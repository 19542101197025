import { useNavigate } from 'react-router-dom'

import { UilArrowRight } from '@iconscout/react-unicons'
import parse from 'html-react-parser'

import { CardDescription } from '~/components'

import { ICardInforProjectProps } from './CardInfo.interface'
import * as S from './CardInfo.styles'
export const CardInfoProject = ({ title, nuOrder, description, guidance, path }: ICardInforProjectProps) => {
  const navigate = useNavigate()

  const handleNavigate = () => {
    localStorage.setItem('@LEKTO:Navigation', location.pathname)
    localStorage.setItem('@LEKTO:nuOrder', String(nuOrder))
    navigate(path)
  }
  return (
    <S.CardContainer>
      <S.TitleRow>
        <S.NuOrder>{nuOrder}</S.NuOrder>
        <S.Title>{title}</S.Title>
      </S.TitleRow>
      <CardDescription description={description} />
      <S.AccordionContainer>
        <S.AccordionSummaryContent
          onClick={() => handleNavigate()}
          data-testid={'exploreClassDirections'}
          expandIcon={
            <S.CircleIcon>
              <UilArrowRight />
            </S.CircleIcon>
          }
        >
          <S.OrientationTitle>{'Orientações da Aula'}</S.OrientationTitle>
        </S.AccordionSummaryContent>

        <S.AccordionDetailsContent>
          <S.ContentParagraphs>
            <S.Paragraph>
              <S.TextParagraph>{parse(String(guidance))}</S.TextParagraph>
            </S.Paragraph>
          </S.ContentParagraphs>
        </S.AccordionDetailsContent>
      </S.AccordionContainer>
    </S.CardContainer>
  )
}
